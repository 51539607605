<template>
  <v-container fluid class="survey-header">
    <v-row align="center" justify="center" style="margin: 20px">
      <v-col cols="12" class="text-center">
        <h1 class="header-title">兒童餵食行為評估測量表</h1>
        <h4 class="sub-title">
          BPFAS (Behavioural Paediatrics Feeding Assessment Scale)
        </h4>
        <div class="info-section">
          <div class="info-group">
            <p class="info-text">
              BPFAS (Behavioural Paediatrics Feeding Assessment Scale, 譯名《兒童餵食行為評估測量表》) 於2001年由加拿大一所餵食門診發表，廣受醫護人員使用，是目前眾多餵食評估中，最能準確及可靠地辨識出學前兒童的餵養問題。
            </p>
            <p class="info-text">
              此評估由家長作答，共35條問題，包括25條關於孩子用餐行為及10條有關家長態度和處理的問題。
            </p>
            <p class="info-text">
              (此評估為中文簡化版，以頻率分數為評估準則，省略煩惱分數部分。問題以英文版本為準。)
            </p>
          </div>
          <p class="cta-text">
            <span class="desktop-text">立即回答以下問卷，了解子女餵養及偏食問題！</span>
            <span class="mobile-text">立即回答以下問卷<span class="break"><br></span>了解子女餵養及偏食問題</span>
          </p>

        </div>
      </v-col>
    </v-row>
  </v-container>
</template>



<script>
import { computed, onMounted, onUnmounted } from 'vue';
import {useWindowWidth} from "@/composables/useWindowWidth";

export default {
  name: 'HeaderComponent',
  setup() {
    const windowWidth = useWindowWidth();
    const isDesktop = computed(() => windowWidth.value >= 960);

    function updateWindowWidth() {
      windowWidth.value = window.innerWidth;
    }

    onMounted(() => {
      window.addEventListener('resize', updateWindowWidth);
    });

    onUnmounted(() => {
      window.removeEventListener('resize', updateWindowWidth);
    });


    return {
      isDesktop
    }
  }
}
</script>

<style scoped>
.survey-header {
  background-image: url('@/assets/banner.jpg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
  display: inline-block;
  padding: 20px 0; /* Add some padding to ensure content doesn't overlap with the image */
}

/* Add an overlay to ensure text is readable */
.survey-header::before {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(60, 186, 217, 0.1); /* This is your original color with 70% opacity */
  z-index: 1;
}

/* Ensure content is above the overlay */
.survey-header .v-row {
  position: relative;
  z-index: 2;
}

/* Rest of your styles remain the same */
.header-title {
  color: #fff;
  font-size: 2.5rem;
  font-weight: 600;
  letter-spacing: 0.05em;
  margin-bottom: 1rem;
  white-space: nowrap;
  overflow: hidden;
}

.sub-title {
  color: #fff;
  font-size: 1.2rem;
  font-weight: 400;
}

.info-section {
  background-color: #e7faff; /* A light and neutral blue */
  padding: 20px;
  border-radius: 10px;
  margin-top: 20px;
  max-width: 1080px;
  margin-left: auto;
  margin-right: auto;
  opacity: 85%;
}

.info-group {
  margin-bottom: 40px;
}

.info-text {
  color: #000; /* Black for contrast and readability */
  font-size: 1rem;
  line-height: 1.5;
  margin-bottom: 10px;
  text-align: justify; /* Justify text */
}

.cta-text {
  color: #000000; /* A bold and attention-grabbing yellow */
  font-size: 1.3rem;
  font-weight: 600;
  text-align: center; /* Justify text */
  white-space: pre-line; /* Preserves newlines and spaces */
}

.desktop-text {
  display: inline;
}

.mobile-text {
  display: none;
}

.break {
  display: none;
}

@media (max-width: 960px) {
  .survey-header .v-row {
    margin: 5px !important; /* Adjust this value as needed */
  }
  .header-title {
    font-size: 1.6rem;
  }
  .sub-title {
    font-size: 1rem;
  }
  .info-text {
    font-size: 1rem;
  }
  .desktop-text {
    display: none;
  }
  .mobile-text {
    display: inline;
  }
  .break {
    display: inline;
  }
}
</style>
