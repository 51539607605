<template>
  <v-app>
    <header-component />
    <component :is="currentComponent" :totalScore="totalScore" @survey-completed="showResults" />
  </v-app>
</template>

<script>
import SurveyComponent from "@/components/SurveyComponent.vue";
import HeaderComponent from "@/components/HeaderComponent.vue";
import ResultComponent from "@/components/ResultComponent.vue";

export default {
  name: 'App',
  components: {
    HeaderComponent,
    SurveyComponent,
    ResultComponent
  },
  data() {
    return {
      currentComponent: 'SurveyComponent',
      totalScore: null
    }
  },
  updated() {
    // This will ensure that the parent's iframe size adjusts whenever the component updates
    this.sendHeightToParent();
  },
  mounted() {
    // Listen for messages from the parent page
    window.addEventListener('message', this.handleMessage, false);
  },
  beforeUnmount() {
    // Clean up the event listener when the component is destroyed
    window.removeEventListener('message', this.handleMessage);
  },
  methods: {
    showResults(score) {
      this.totalScore = score;
      this.currentComponent = 'ResultComponent';
    },
    sendHeightToParent() {
      // Get the current scrollHeight of the component
      const height = this.$el.scrollHeight;
      // Post the message to the parent frame
      window.parent.postMessage({
        frameHeight: height
      }, 'https://feedingfoodies.com.hk');
    },
    handleMessage(event) {
      // Check the origin and the data of the received message
      if (event.origin === 'https://feedingfoodies.com.hk' && event.data === 'requestHeight') {
        // Send the height to the parent frame if requested
        this.sendHeightToParent();
      }
    }
  }
}
</script>

<style>
#app {
  font-family: 'JF Open', Avenir, Helvetica, Arial, sans-serif;
}
</style>
