<template>
  <v-container>
    <div class="result-page">
      <h2 class="result-title">評估結果</h2>
      <p class="result-score">你的評估分數為：</p>
      <div class="score-section">
        <p class="score" :class="{ 'high-score': totalScore > 84 }">{{ totalScore }}</p>
        <p class="total-score">/ 175</p>
      </div>
      <div class="spacing"></div>
      <p class="result-text">頻率分數最高是175分，分數高於84，代表孩子經常有不同的餵養及偏食問題，而分數越高代表問題越嚴重，為孩子整體健康帶來負面影響，建譤尋求專業協助，全面評估孩子生長情況，由餵養習慣入手，逆轉偏食習慣。</p>
      <div class="spacing"></div>
      <p class="email-notification">
        本次評估報告已發送至您的電子郵箱。(如果未收到郵件，請檢查您的垃圾郵件資料夾。)
      </p>
      <div class="spacing"></div>
      <a href="https://m.me/feedingfoodies.hk" target="_blank" class="consultation-button">
        <i class="fab fa-facebook-messenger"></i> 立即預約諮詢
      </a>
    </div>
  </v-container>
</template>

<script>
export default {
  name: 'ResultPage',
  props: {
    totalScore: {
      type: Number,
      required: true
    }
  },
  computed: {
    scoreColor() {
      return this.totalScore > 84 ? 'red' : 'green';  /* Red color for scores higher than 84, green color otherwise */
    }
  },
  methods: {
    bookConsultation() {
      // Add your booking logic here
    }
  }
};
</script>

<style scoped>
.result-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 50px 20px;
  background: #f9f9f9;
  border-radius: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}

.result-title {
  font-size: 2.5rem; /* Bigger title */
  font-weight: bold;
  letter-spacing: 0.05em;  /* 增加字元間距 */
  color: #333;
  margin-bottom: 20px;
}

.result-score {
  font-size: 1.5rem;
  color: #333;
  margin-top: 20px;
}

.score-section {
  display: flex;
  align-items: baseline;  /* Align the score and the total score vertically */
}

.score {
  font-size: 5rem;
  font-weight: bold;
  color: #5cb85c;  /* Red color for scores less than equal 84 */
}

.high-score {
  color: #ff6347;  /* Red color for scores higher than 84 */
}

.total-score {
  font-size: 2rem;  /* Smaller font size */
  color: #333;
  margin-left: 10px;  /* Space between the score and the total score */
}

.spacing {
  height: 50px; /* More spacing below score */
}

.result-text {
  font-size: 1.2rem;
  color: #666;
  text-align: center;
  letter-spacing: 0.05em;  /* 增加字元間距 */
  max-width: 1000px;  /* Maximum width */
  margin-left: auto;  /* Center the text horizontally */
  margin-right: auto; /* Center the text horizontally */
}

.consultation-button {
  display: inline-flex;  /* Make the link flex to center the items */
  align-items: center;  /* Vertically center the icon and text */
  justify-content: center;  /* Horizontally center the icon and text */
  margin-top: 20px;
  padding: 15px 30px;
  font-size: 1.3rem;
  font-weight: bold;
  letter-spacing: 0.1em;  /* 增加字元間距 */
  color: #fff;
  background-color: #0084FF; /* Messenger blue */
  text-decoration: none;
  border-radius: 5px;
  cursor: pointer;
}

.consultation-button i {
  margin-right: 10px;  /* Add some space between the icon and the text */
}

</style>

