<template>
  <div class="question-container">
    <h3 class="question-zh">{{ question.q_zh }}</h3>
    <p class="question-en">{{ question.q_en }}</p>
  </div>
  <div class="radio-buttons-container">
    <span class="label label-1">從不</span>
    <div class="radio-buttons" role="radiogroup">
      <button
          v-for="n in 5"
          :key="n"
          :aria-checked="selected === n"
          role="radio"
          :class="['radio-button', 'button-' + n, { 'selected': selected === n }]"
          @click="updateSelection(n)"
          :disabled="false"
      ></button>
    </div>
    <span class="label label-5">總是</span>
  </div>
</template>

<script>
export default {
  props: {
    question: {
      type: Object,
      required: true
    },
    reverse: {
      type: Boolean,
      default: false  // 默認值為 false
    },
    modelValue: {
      type: Number,
      default: null
    }
  },
  data() {
    return {
      selected: null,  // No initial value
    };
  },
  methods: {
    updateSelection(value) {
      let score = this.reverse ? 6 - value : value;
      this.selected = value;
      this.$emit('update:modelValue', score);
    }
  }
};
</script>

<style scoped>
.question-container {
  margin-bottom: 1rem;  /* 增加下邊距 */
  text-align: center;  /* 將文本居中對齊 */
}

.question-zh {
  font-size: 1.4rem;
  font-weight: bold;
  letter-spacing: 0.05em;  /* 增加字元間距 */
  margin-bottom: 0.5rem;
}

.question-en {
  font-size: 1.1rem;
  color: #666;
  margin-bottom: 1rem;
}

.radio-buttons-container {
  display: flex;
  align-items: center;
  max-width: 800px;  /* or 100vw */
  /* 增加下邊距 */
  margin: auto auto 2rem;
}

.radio-buttons {
  display: flex;
  justify-content: space-around;
  align-items: center;  /* 垂直居中 */
  flex-grow: 1;
  margin: 0 10px;
}

.radio-button {
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 50%;
  background-color: transparent;
  border: 3px solid #ccc;  /* 更粗的邊框 */
  cursor: pointer;
  transition: background-color 0.3s, border-color 0.3s;
}

.radio-button.button-1 {
  border-color: #E50A3E;
  color: rgba(229, 10, 62, 0.7);  /* 淺一點的顏色 */
}

.radio-button.button-2 {
  border-color: #F25129;
  color: rgba(242, 81, 41, 0.7);  /* 淺一點的顏色 */
}

.radio-button.button-3 {
  border-color: #FF9814;
  color: rgba(255, 152, 20, 0.7);  /* 淺一點的顏色 */
}

.radio-button.button-4 {
  border-color: #8CA554;
  color: rgba(140, 165, 84, 0.7);  /* 淺一點的顏色 */
}

.radio-button.button-5 {
  border-color: #19B394;
  color: rgba(25, 179, 148, 0.7);  /* 淺一點的顏色 */
}

.radio-button.selected {
  background-color: currentColor;
  border-color: currentColor;
}

.label {
  font-size: 1.2rem;  /* 更大的字體 */
  letter-spacing: 0.05em;  /* 增加字元間距 */
}

.label-1 {
  color: #E50A3E;
}

.label-5 {
  color: #19B394;
}

@media (max-width: 960px) {
  .question-container {
    margin: 1rem;  /* 增加下邊距 */
  }
  .label {
    font-size: 1rem;
  }
  .question-zh {
    font-size: 1.15rem;
  }
  .question-en {
    font-size: 1rem;
  }
}
</style>
