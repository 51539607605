<template>
  <v-container ref="pageContainer">
    <v-alert v-model="showError" type="warning" dismissible>{{ errorMessage }}</v-alert>
    <div class="page-container">
      <div v-if="isEmailPage" class="email-page">
        <div class="email-header">
          <h2 class="email-title">最後一步！</h2>
        </div>
        <p class="email-instruction">請輪入電郵地址以取得您孩子的評估報告!</p>
        <v-text-field
            label="電郵地址"
            v-model="email"
            :rules="emailRules"
            required
            class="email-input"
        ></v-text-field>
        <v-btn class="view-results-button" @click="viewResults">
          <span class="button-text">立即查看評估結果</span>
          <v-icon right>mdi-arrow-right</v-icon>
        </v-btn>
      </div>
      <div v-else>
        <v-progress-linear
            :model-value="progress"
            color="#FF914D"
            height="30"
            striped
            style="margin-bottom: 20px;"
        >
          <template v-slot:default="{ value }">
            <strong>{{ Math.ceil(value) }}%</strong>
          </template>
        </v-progress-linear>
        <h2 class="page-title">{{ pages[currentPage].title }}</h2>
        <v-row v-for="question in currentQuestions" :key="question.id" class="my-5">
          <v-col cols="12">
            <rating-scale
                v-model="question.answer"
                :question="question"
                :reverse="question.reverse_scoring"
                @update:modelValue="updateProgress"
            />
          </v-col>
        </v-row>
        <div class="button-container">
          <v-btn class="custom-button" @touchstart="touchStart" @touchend="touchEnd" @click="click" :disabled="isNextPageRunning">
            <span class="button-text">{{ buttonText }}</span>
            <v-icon v-if="isNextPage" right>mdi-arrow-right</v-icon>
            <v-icon v-else right>mdi-check</v-icon>
          </v-btn>
        </div>
      </div>
    </div>
  </v-container>
</template>

<script setup>
import { ref, computed, defineEmits, nextTick  } from 'vue';
import RatingScale from '@/components/CustomRatingScale.vue'; // Ensure path is correct
import pages from '@/assets/survey.json';  // adjust the path as needed
import axios from 'axios';  // Make sure to install axios
const emit = defineEmits(['survey-completed']);

// Add refs for the error message and its visibility
const showError = ref(false);
const errorMessage = ref('');
const pageContainer = ref(null);
const currentPage = ref('1'); // Set the initial page to '1'
const email = ref('');
const isNextPageRunning = ref(false);
const progress = ref(0);

// Compute whether the current page is not the last one
const isNextPage = computed(() => parseInt(currentPage.value) < Object.keys(pages).length);
const isEmailPage = computed(() => currentPage.value === 'email');

// Compute the current questions based on the current page
const currentQuestions = computed(() => {
  return pages[currentPage.value].question;
});

const totalQuestions = computed(() => {
  return Object.values(pages).reduce((total, page) => total + page.question.length, 0);
});

// Use watchEffect to track changes to each question's answer
const updateProgress = (() => {
  let answeredCount = 0;
  Object.values(pages).forEach(page => {
    page.question.forEach(question => {
      if (question.answer !== undefined) {
        answeredCount++;
      }
    });
  });
  progress.value = (answeredCount / totalQuestions.value) * 100;
});

// Add a method to update the answer of a question
const buttonText = computed(() => {
  return parseInt(currentPage.value) < Object.keys(pages).length ? '下一頁' : '提交';
});

const emailRules = [
  v => !!v || 'Email is required',
  v => /.+@.+\..+/.test(v) || '請輸入有效的電子郵件',
];

let touchStartY;

const touchStart = (event) => {
  touchStartY = event.touches[0].clientY;
};

const touchEnd = (event) => {
  // If the vertical movement is less than 10 pixels, treat it as a click
  if (Math.abs(touchStartY - event.changedTouches[0].clientY) < 10) {
    nextPage();
  }
};

const click = () => {
  nextPage();
};

function smoothScrollTo(element) {
  const start = window.pageYOffset;
  const target = element.getBoundingClientRect().top + start;
  const startTime = 'now' in window.performance ? performance.now() : new Date().getTime();

  const duration = 200;

  if (!('requestAnimationFrame' in window)) {
    window.scroll(0, target);
    // Send the target scroll position to the parent window
    window.parent.postMessage({ type: 'scrollTo', coordinates: { x: 0, y: target } }, 'https://feedingfoodies.com.hk');
    return;
  }

  function scroll(currentTime) {
    const timeElapsed = currentTime - startTime;
    const pageProgress = Math.min(timeElapsed / duration, 1);
    const currentY = start + (target - start) * pageProgress;

    window.scroll(0, currentY);
    // Send the current scroll position to the parent window
    window.parent.postMessage({ type: 'scrollTo', coordinates: { x: 0, y: currentY } }, 'https://feedingfoodies.com.hk');

    if (timeElapsed < duration) {
      requestAnimationFrame(scroll);
    }
  }

  requestAnimationFrame(scroll);
}

const nextPage = async () => {
  // Disable the next button to prevent multiple submissions
  isNextPageRunning.value = true;

  // Check if all questions on the current page have been answered
  const allQuestionsAnswered = currentQuestions.value.every(question => question.answer !== undefined);

  if (allQuestionsAnswered) {
    const totalNumberOfPages = Object.keys(pages).length;
    const currentPageNumber = parseInt(currentPage.value, 10);

    if (currentPageNumber < totalNumberOfPages) {
      // Move to the next page if it is not the last page
      currentPage.value = (currentPageNumber + 1).toString();
      showError.value = false;
    } else {
      // If it is the last question page, go to the email page
      currentPage.value = 'email';
    }
  } else {
    // Show error if not all questions are answered
    showError.value = true;
    errorMessage.value = '請回答本頁的所有問題';
  }

  // Wait for the next DOM update cycle before scrolling
  await nextTick();

  // Scroll to the top of the page container
  smoothScrollTo(pageContainer.value.$el);

  // Re-enable the next button
  isNextPageRunning.value = false;
};

// Modify the viewResults method
const viewResults = async () => {
  if (email.value && emailRules.every(rule => rule(email.value))) {
    // Email is valid
    // Calculate the total score
    const totalScore = Object.values(pages).reduce((total, page) => {
      return total + page.question.reduce((total, question) => {
        return total + (question.answer || 0);
      }, 0);
    }, 0);

    let surveyResults = {}

    // Prepare the survey results
    const data = Object.values(pages).reduce((acc, page) => {
      if (!acc[page.title]) {
        acc[page.title] = [];
      }
      page.question.forEach(question => {
        acc[page.title].push({
          id: question.id,
          q_zh: question.q_zh,
          q_en: question.q_en,
          score: question.answer
        });
      });
      return acc;
    }, {});

    surveyResults.email = email.value;
    surveyResults.detail = data;
    surveyResults.score = totalScore;


    // Call your API with the survey results
    axios.post('https://app.feedingfoodies.com.hk/submit-survey', surveyResults)
        .then(response => {
          // Handle success
          console.log(response.data);
          // Emit the event after the API call is successful
          emit('survey-completed', totalScore);
        })
        .catch(error => {
          // Handle error
          console.error('Error posting survey results:', error);
          showError.value = true;
          errorMessage.value = '無法提交評估結果，請稍後再試';
        });
  } else {
    showError.value = true;
    errorMessage.value = '請輸入有效的電子郵件';
    // Scroll to the top of the page container
    await nextTick();
    smoothScrollTo(pageContainer.value.$el);

    // Enable the button
    isNextPageRunning.value = false;
  }
};
</script>


<style scoped>
.page-container {
  padding: 20px;
  max-width: 1000px;  /* Set a max-width */
  margin: auto;  /* Center the container */

}

.page-title {
  color: #333;  /* Darker text color */
  font-size: 1.2rem;  /* Larger font size */
  font-weight: 600;  /* Bolder font weight */
  text-align: left;  /* Align the text to the left */
  margin-bottom: 20px;  /* Add some space below the title */
}

.button-container {
  display: flex;
  justify-content: center;
  margin: 30px 0;  /* Increase vertical spacing */
}

.custom-button {
  background-color: #ff6b6b;  /* Red */
  color: #fff;  /* White */
  border: none;
  border-radius: 50px;
  padding: 40px 100px;  /* Increase padding to make the button bigger */
  font-size: 1.4rem;  /* Increase font size */
  font-weight: 600;
  cursor: pointer;
  transition: background-color 0.3s;
  display: flex;  /* Add this line */
  align-items: center;  /* Add this line */
  justify-content: center;  /* Add this line */
}

.custom-button:hover {
  background-color: #ff8787;  /* Lighter red */
}

.button-text {
  text-align: center;  /* Add this line */
}

.email-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #f9f9f9;
  border-radius: 10px;
  animation: fadeIn 1s;
  gap: 20px;
  padding: 20px 20px 100px;
}

.email-header {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.email-title {
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 20px;
  color: #4a90e2;
}

.email-instruction {
  font-size: 1.2rem;
  margin-bottom: 30px;
  color: #333;  /* Darker text color */
  text-align: center;  /* Center the text */
}

.view-results-button {
  background-color: #19B394;  /* Green */
  color: #fff;  /* White */
  border: none;
  border-radius: 50px;
  padding: 40px 100px;  /* Increase padding to make the button bigger */
  font-size: 1.5rem;  /* Increase font size */
  font-weight: 600;
  cursor: pointer;
  transition: background-color 0.3s;
  display: flex;  /* Add this line */
  align-items: center;  /* Add this line */
  justify-content: center;  /* Add this line */
  margin-top: 20px;  /* Increase space above the button */
}

.view-results-button:hover {
  background-color: #1BCBA6;  /* Lighter green */
}

.email-input {
  width: 100%;  /* Set the width to 100% */
  max-width: 600px;
}

</style>
